'use client';

import { useScrollSpy } from '@/hooks/useScrollSpy';
import clsx from 'clsx';
import { Arrow } from '@/images/svg/Arrow';

interface IProps {
  list: string[];
  wrapperClassName?: string;
  amountOfListItems?: number;
  themeColor?: 'white' | 'purple';
}

export const NavBar = ({ list, wrapperClassName, amountOfListItems = 6, themeColor = 'purple' }: IProps) => {
  const { activeItem, scrollToItem } = useScrollSpy('.anchor-block', 'body');

  return (
    <div
      className={clsx(
        `
          large-screen:w-[calc((100%-1376px)/2)]
          lg:flex w-[128px] mt-[156px] hidden justify-end fixed z-50
          [@media(max-height:735px)]:!mt-[128px]
        `,
        {
          [`${wrapperClassName}`]: wrapperClassName,
        },
      )}
    >
      <nav className="w-[128px]">
        <ul
          className={clsx(
            `flex flex-col gap-[10px] w-[128px] items-center desktop:items-start 
             h-[calc(100vh-96px-60px-60px)] justify-between       
          `,
            {
              [`max-h-[740px] [@media(max-height:735px)]:gap-[2px]`]: list.length > amountOfListItems,
              [`max-h-[600px]`]: list.length <= amountOfListItems,
              [`max-h-[450px]`]: list.length <= 4,
            },
          )}
        >
          {list.map((listItem, index) => {
            const isActive = activeItem === index;

            return (
              <li
                key={listItem}
                onClick={() => scrollToItem(index)}
                className={clsx(
                  `flex flex-col gap-[12px] items-center 
                 cursor-pointer duration-300 relative leading-[12px] group
                 `,
                  {
                    [`[@media(max-height:735px)]:gap-[4px]`]: list.length > amountOfListItems,
                  },
                )}
              >
                <div
                  className={clsx(
                    `[writing-mode:vertical-lr] rotate-180 uppercase relative ml-[2px] flex justify-start`,
                    {
                      [`[@media(max-height:735px)]:ml-0`]: list.length > amountOfListItems,
                    },
                  )}
                >
                  <span
                    className={clsx(
                      `absolute top-0 left-1/2 translate-x-[-50%] duration-300 text-[10px] group-hover:text-green-7`,
                      {
                        ['scale-[1.1] text-green-7 font-mono']: isActive,
                        ['text-purple-6']: !isActive && themeColor === 'purple',
                        ['text-white']: !isActive && themeColor === 'white',
                        [`[@media(max-height:735px)]:text-[8px]`]: list.length > amountOfListItems,
                      },
                    )}
                  >
                    {listItem}
                  </span>

                  <span
                    aria-hidden="true"
                    className="font-mono bg-blue-400 text-[12px] relative invisible"
                  >
                    {listItem}
                  </span>
                </div>
                <div className="h-[10px] w-[10px] relative">
                  <div
                    className={clsx(
                      `w-[4px] h-[4px] rounded-full duration-300 
                    absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] group-hover:bg-green-7`,
                      {
                        ['scale-0']: isActive,
                        ['scale-1']: !isActive,
                        ['bg-purple-6']: !isActive && themeColor === 'purple',
                        ['bg-white']: !isActive && themeColor === 'white',
                      },
                    )}
                  />
                  <Arrow
                    variant="home-green"
                    className={clsx(
                      'rotate-90 duration-300 absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]',
                      {
                        ['scale-1']: isActive,
                        ['scale-0']: !isActive,
                      },
                    )}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};
